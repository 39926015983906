import React from "react";
import DefaultLayout from "components/layout/default";
import { FaCheck } from "react-icons/fa";
import styles from "styles/pages/aws-marketplace.module.scss";

import wardenImage from "images/product-warden.svg";
import whiteTopRadius from "images/product-radius-top-cap.svg";
import icon1 from "images/registration/icon-01.svg";
import icon2 from "images/registration/icon-02.svg";
import icon3 from "images/registration/icon-03.svg";
import icon4 from "images/registration/icon-04.svg";
import computer from "images/computer-screen-warden.png";
import awslogo from "images/logo-aws-co-brand.png";
import awsMplogo from "images/logos/aws-mp.png";
import bukalapak from "images/logos/bukalapak.jpg";
import gojek from "images/logos/gojek.png";
import moneysmart from "images/logos/moneysmart.svg";
import propertyguru from "images/logos/propertyguru.svg";
import fave from "images/logos/fave.png";
import ninjavan from "images/logos/ninjavan.svg";

function AwsMarketplacePage(props) {
  return (
    <DefaultLayout
      nav={false}
      location={props.location}
      title={"Horangi - AWS Marketplace"}
      description={""}
    >
      <section className="xxl:mb-40 h-screen">
        <div>
          <img
            className="mx-auto"
            src={awslogo}
            alt=""
            style={{ width: 380 }}
          />
        </div>
        <div className="flex w-full flex-col sm:flex-row md:px-8 z-10 relative pt-5 lg:mt-0">
          <div className="w-full sm:w-1/5 flex flex-col justify-start p-10  ">
            <WardenVerticalGraphic line={false} height={200} />
          </div>
          <div className="w-full sm:w-2/5">
            <WardenMarketplaceTop />
          </div>
          <div className="w-full sm:w-2/5 mt-10 sm:mt-0">
            <img
              className="relative p-10 sm:p-0 sm:-ml-20 md:-ml-10 -mt-20 lg:mt-0"
              src={computer}
              alt=""
            />
          </div>
        </div>
      </section>

      <img
        src={whiteTopRadius}
        alt=""
        className={`relative mt-20 sm:mt-0 lg:-mt-64 xxl:-mt-128 w-full sm:block pt-40`}
      />
      <section
        style={{ marginTop: -1 }}
        className={`bg-white text-black ${styles.bottom_section}`}
      >
        <div className="relative -mt-64 md:-mt-48 lg:-mt-64  flex flex-col mb-20">
          <h1
            className={`relative font-bold text-black text-center text-2xl sm:text-2xl xxl:text-4xl text-center mt-96 sm:mt-10 xxl:-mt-20`}
          >
            TRUSTED BY ASIA’S INDUSTRY LEADERS
          </h1>
          <div className="w-full flex flex-col sm:flex-row relative mt-20">
            <div className="w-full sm:w-1/3">
              <img
                className="mx-auto"
                src={bukalapak}
                alt=""
                style={{ width: 150 }}
              />
            </div>
            <div className="w-full sm:w-1/3 mt-10 sm:mt-0">
              <img
                className="mx-auto"
                src={gojek}
                alt=""
                style={{ width: 150 }}
              />
            </div>
            <div className="w-full sm:w-1/3 mt-10 sm:mt-0">
              <img
                className="mx-auto"
                src={moneysmart}
                alt=""
                style={{ width: 150 }}
              />
            </div>
          </div>
          <div className="w-full flex flex-col sm:flex-row mt-10">
            <div className="w-full sm:w-1/3">
              <img
                className="mx-auto"
                src={propertyguru}
                alt=""
                style={{ width: 150 }}
              />
            </div>
            <div className="w-full sm:w-1/3 mt-10 sm:mt-0">
              <img
                className="mx-auto pb-1"
                src={fave}
                alt=""
                style={{ height: 50 }}
              />
            </div>
            <div className="w-full sm:w-1/3 mt-10 sm:mt-0">
              <img
                className="mx-auto"
                src={ninjavan}
                alt=""
                style={{ width: 150 }}
              />
            </div>
          </div>
        </div>
        <h1
          className={`${styles.title} text-2xl sm:text-5xl text-center relative mt-20`}
          style={{ maxWidth: 900 }}
        >
          Connect your AWS account and see your first scan result in minutes
        </h1>
        <div className="flex mt-20 flex-col sm:flex-row">
          <div className="w-full sm:w-1/2 flex justify-center mr-20">
            <div className="p-5" style={{ maxWidth: 450 }}>
              <img
                src={icon1}
                className="mr-4 sm:pl-5 mt-2 mb-5 sm:self-start"
                alt=""
              />
              <div className="pl-0 sm:pl-5">
                <h6 className="sm:text-xl font-bold mb-2 sm:mb-5 ">
                  Continuous Vulnerability Monitoring
                </h6>
                <div className="w-full">
                  Prevent vulnerabilities like exploitable configuration states
                  from turning into incidents.
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 flex justify-center">
            <div className="p-5" style={{ maxWidth: 450 }}>
              <img
                src={icon2}
                className="mr-4 sm:pl-5 mt-2 mb-5 sm:self-start"
                alt=""
              />
              <div className="pl-0 sm:pl-5">
                <h6 className="sm:text-xl font-bold mb-2 sm:mb-5 ">
                  Built-in Compliance Reporting
                </h6>
                <div className="w-full">
                  Assess your compliance posture against the CIS Benchmark and
                  AWS Well-Architected Framework.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-5 sm:mt-20 flex-col sm:flex-row">
          <div className="w-full sm:w-1/2  flex justify-center mr-20">
            <div className="p-5" style={{ maxWidth: 450 }}>
              <img
                src={icon3}
                className="mr-4 sm:pl-5 mt-2 mb-5 sm:self-start"
                alt=""
              />
              <div className="pl-0 sm:pl-5">
                <h6 className="sm:text-xl font-bold mb-2 sm:mb-5">
                  Integrated SaaS Platform
                </h6>
                <div className="w-full">
                  Your Warden trial gives you full access to Horangi Code
                  Scanner and Web Scanner, enabling vulnerability scans in your
                  application source code and web targets.
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 flex justify-center">
            <div className="p-5" style={{ maxWidth: 450 }}>
              <img
                src={icon4}
                className="mr-4 sm:pl-5 mt-2 mb-5 sm:self-start"
                alt=""
              />
              <div className="pl-0 sm:pl-5">
                <h6 className="sm:text-xl font-bold mb-2 sm:mb-5">
                  End-to-End Customer Service
                </h6>
                <div className="w-full">
                  Customize your trial experience by meeting with our
                  consultants for a 15-minute walkthrough of the Storyfier
                  platform.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:pl-40 mt-20"></div>
      </section>

      <section style={{ height: 50 }} className="bg-black" />
    </DefaultLayout>
  );
}

const WardenMarketplaceTop = () => (
  <div className={`pl-5 pr-5 pt-5 text-white mt-10 sm:mt-0`}>
    <div className="mb-5">
      <h1
        className={`mb-4 text-2xl text-center sm:text-left sm:text-2xl xxl:text-4xl w-full sm:w-4/5 ${styles.title}`}
      >
        Stop the most common cause of cloud data breaches with Warden.
      </h1>
      <p
        className={`mb-10 mt-10 sm:mt-0 text-sm xxl:text-normal text-center sm:text-left w-full sm:w-4/5`}
      >
        Warden provides continuous vulnerability and compliance monitoring,
        giving you visibility and control of your top cloud risks.
      </p>
      <a
        className="btn bg-orange block text-center m-5 sm:text-left sm:m-0 sm:inline-block"
        href="https://aws.amazon.com/marketplace/pp/B07S63HG25/?&trk=_a131L0000058VddQAE&trkCampaign=AWSMP_Startup_Pilot_Seller_Led_Horangi&sc_channel=&sc_campaign=MP_Startup_Pilot_DMS_Horangi&sc_outcome=Marketplace&sc_geo=mult"
      >
        Start 14-Day Trial
      </a>
      <img
        src={awsMplogo}
        alt=""
        style={{ maxWidth: 200 }}
        className={`mt-3 mx-auto sm:mx-0`}
      />
      <ul className={`mb-10 mt-5 w-4/5 text-xs xxl:text-sm`}>
        <li className="flex flex-row">
          <FaCheck style={{ marginRight: 10, marginTop: 3 }} />
          Free for 14 days
        </li>
        <li className="flex flex-row mt-3 mb-3">
          <FaCheck style={{ marginRight: 10, marginTop: 3, fontSize: 16 }} />
          Get full access to all of Horangi&apos;s software solutions on
          Storyfier
        </li>
        <li className="flex flex-row">
          <FaCheck style={{ marginRight: 10, marginTop: 3 }} />
          Obligation and credit card free
        </li>
      </ul>
    </div>
  </div>
);

const WardenVerticalGraphic = ({ height, className }) => {
  return (
    <div className="flex flex-col sm:pl-5" style={{ height: "100%" }}>
      <img
        className={className}
        style={{ height }}
        alt="logo"
        src={wardenImage}
      />
      <div
        id="vertical-line"
        className={`${styles.verticalLine} bg-white sm:h-screen self-center`}
      />
    </div>
  );
};

export default React.memo(AwsMarketplacePage);
